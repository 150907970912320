import Vue from 'vue';
import Router from 'vue-router';
import ChangeTemporaryPassword from './views/ChangeTemporaryPassword.vue';
import CompletePasswordReset from './views/CompletePasswordReset.vue';
import StartPasswordReset from './views/StartPasswordReset.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/reset-password',
      name: 'StartPasswordReset',
      component: StartPasswordReset,
    },
    {
      path: '/reset-password/:resetKey',
      name: 'CompletePasswordReset',
      component: CompletePasswordReset,
    },
    {
      path: '/temporary',
      name: 'ChangeTemporaryPassword',
      component: ChangeTemporaryPassword,
    },
  ],
});
