

























































import Vue from 'vue';
import { Route } from 'vue-router';
import * as EmailValidator from 'email-validator';

export default Vue.extend({
  name: 'CompletePasswordReset',
  data() {
    return {
      complete: false,
      confirmPassword: '',
      confirmPasswordValid: true,
      newPassword: '',
      newPasswordValid: true,
      password: '',
      passwordValid: true,
      serverError: false,
      submitting: false,
    };
  },
  methods: {
    submit() {
      if (
        !this.submitting
        && this.validateNewPassword(this.newPassword)
        && this.validateConfirmPassword(this.newPassword, this.confirmPassword)
      ) {
        this.submitting = true;
        this.serverError = false;

        fetch('https://api.nextechar.com/users/reset/complete', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: this.newPassword,
            resetKey: this.$route.params.resetKey,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw response;
            }

            this.complete = true;
            this.submitting = false;
          })
          .catch((err) => {
            console.log(err);

            this.serverError = true;
            this.submitting = false;
          });
      }
    },
    validateConfirmPassword(pwd1: string, pwd2: string): boolean {
      const valid = pwd1 === pwd2;

      this.confirmPasswordValid = valid;
      return valid;
    },
    validateNewPassword(pwd: string): boolean {
      const valid = pwd.length >= 8;

      this.newPasswordValid = valid;
      return valid;
    },
  },
});
