



























































































import Vue from 'vue';
import { Route } from 'vue-router';
import * as EmailValidator from 'email-validator';

export default Vue.extend({
  name: 'ChangeTemporaryPassword',
  data() {
    return {
      complete: false,
      confirmPassword: '',
      confirmPasswordValid: true,
      email: '',
      emailValid: true,
      newPassword: '',
      newPasswordValid: true,
      password: '',
      passwordValid: true,
      serverError: false,
      submitting: false,
    };
  },
  created() {
    const email = this.$route.query.email as string;

    if (email !== undefined && this.validateEmail(email, false)) {
      this.email = email;
    }
  },
  methods: {
    submit() {
      if (
        !this.submitting
        && this.validateEmail(this.email)
        && this.validatePassword(this.password)
        && this.validateNewPassword(this.newPassword)
        && this.validateConfirmPassword(this.newPassword, this.confirmPassword)
      ) {
        this.submitting = true;
        this.serverError = false;

        fetch('https://api.nextechar.com/users/temporary', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.email,
            password: this.password,
            newPassword: this.newPassword,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw response;
            }

            this.complete = true;
            this.submitting = false;
          })
          .catch((err) => {
            console.log(err);

            this.serverError = true;
            this.submitting = false;
          });
      }
    },
    validateConfirmPassword(pwd1: string, pwd2: string): boolean {
      const valid = pwd1 === pwd2;

      this.confirmPasswordValid = valid;
      return valid;
    },
    validateEmail(email: string, flag: boolean = true): boolean {
      const valid = EmailValidator.validate(email);

      if (flag) {
        this.emailValid = valid;
      }

      return valid;
    },
    validateNewPassword(pwd: string): boolean {
      const valid = pwd.length >= 8;

      this.newPasswordValid = valid;
      return valid;
    },
    validatePassword(pwd: string): boolean {
      const valid = pwd.length > 0;

      this.passwordValid = valid;
      return valid;
    },
  },
});
