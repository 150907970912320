









































import Vue from 'vue';
import { Route } from 'vue-router';
import * as EmailValidator from 'email-validator';

export default Vue.extend({
  name: 'StartPasswordReset',
  data() {
    return {
      complete: false,
      email: '',
      emailValid: true,
      serverError: false,
      submitting: false,
    };
  },
  created() {
    const email = this.$route.query.email as string;

    if (email !== undefined && this.validateEmail(email, false)) {
      this.email = email;
    }
  },
  methods: {
    submit() {
      if (!this.submitting && this.validateEmail(this.email)) {
        this.submitting = true;
        this.serverError = false;

        fetch('https://api.nextechar.com/users/reset/start', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.email,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw response;
            }

            this.complete = true;
            this.submitting = false;
          })
          .catch((err) => {
            console.log(err);

            this.serverError = true;
            this.submitting = false;
          });
      }
    },
    validateEmail(email: string, flag: boolean = true): boolean {
      const valid = EmailValidator.validate(email);

      if (flag) {
        this.emailValid = valid;
      }

      return valid;
    },
  },
});
